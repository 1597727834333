import React from 'react'
import Form from 'components/BookingForm'
import { navigate } from 'gatsby'
import useApi from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { getSlotTimetable } from 'actions/slotTimetable'
import { getSlot } from 'actions/slot'
import { book } from 'actions/booking'

const NewBooking = ({ slotTimetableId }) => {
  const [slotTimetable, slot] = useApi(() =>
    getSlotTimetable(slotTimetableId).then(async (d) => [d, await getSlot(d.slotId)])
  )
  const [, auth] = useAuth()

  const onSubmit = async (data) =>
    book(slotTimetableId, auth?.uid, data, slot, null, null, true).then((doc) => {
      navigate(`/admin/bookings/edit/${doc.id}`)
    })

  const onDiscard = () => {
    navigate('/admin/bookings')
  }

  return (
    <Form
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      title="New Booking"
      slotTimetable={slotTimetable}
      submitButtonText="Create"
      editable
    />
  )
}
export default NewBooking
